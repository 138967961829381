import React from 'react';

const HomePage = () => {
    return (
        <div>
            <div className="infamous">
                <div className='fiery'>
                    The Infamous Machine
                </div>
            </div>
        </div>
    );
}

export default HomePage;